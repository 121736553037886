import React from 'react';
import PropTypes from 'prop-types';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import withTranslate from '../../../../common/hoc/with-translate';
import { classes } from './page-title.st.css';

const PageTitle = ({ t }) => (
  <Text className={classes.root} tagName="h3" typography={TYPOGRAPHY.smallTitle}>
    {t('page-title')}
  </Text>
);

PageTitle.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslate(PageTitle);
